import React from 'react';

import './index.css';

const Loading = () => {
    return (
        <div className="loading">
            <div className="content">
                {/* <img src='./assets/images/loading/loading.gif' alt='loading' /> */}
            </div>
        </div>
    )
}

export default Loading;
