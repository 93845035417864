import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from './components/layout';

const App = () => {
 //fetch("http://birksweb05:81/index.php").then(response => response.json()).then(result => console.log(result)).catch(err => console.log(err));
  return (
    <Router>
        <Layout />
    </Router>
  );
}

export default App;
