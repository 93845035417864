const CONSTANTS = {
    DESKTOP : 'Desktop',
    MOBILE : 'Mobile',
    MOBILE_SIZE: 992,
    IMAGES_FOLDER : '/assets/images/',
    SERVER_URL : "https://brinkhaus-api.summit-tech.ca/api/",
    WEB_SITE_URL: ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && false) ? "https://brinkhaus.summit-tech.ca/" : "https://brinkhaus.ca/",
    BINKHAUS_LOGO_PATH: "assets/images/header/Brinkhaus.svg",
    BRINKHAUS_ICON_PATH:"/favicon.ico",
    TWITTER_USERNAME : '',
    GET_URL : ({...params}) => `${CONSTANTS.SERVER_URL}index.php?k=${params.a}&name=${params.b}`,
    KS : {
        home: '7ddb298c3df14f76fa90ce3710e942ec',
        contact: 'af5efea250326c1c34d69aa9364b482c',
        about: '89f007c27be8fe335670bbd9808aa518',
        events: '16908b0605f2645dfcb4c3a8d248cef3',
        watches: 'addc251194dc1d41d835c1e30e45d190',
        jewelleries: 'a2fc1738d7a33330d8f391aeb3ad02c7',
        allCollections: 'd522abc62a84bd895a23724f8fdc4b1c',
        collection: '3d1465c07d9a4d6816301b0d83cd17ba',
        product: '5b92d8468e6bc69f1f4de800b66a8871',
        services: 'e222412607f75aa6d885c2519cfa498b',
        designers: '4cae15363f062c712289a6db11a6086f',
        boutique: '8756e4a9b0294e5966c946b0d817dd81',
        FAQ: 'cdbf6a7ad9f1b890eae2fd4094e5ec05',
        Cartier: '1aa6a65a8b072d11967a545d9caca421',
        PatekPhilippe: '799648b32f6357b665d3da8679a26c1d',
        DevPage: 'f5b1db4fca85f56bbcd3856ef19e199f',
        privacyPolicy: '5f618c0874c040cd405d9e720696b41e',
//        Wolf: 'fb306441d396fb3d51233aeda17b239a',
    },
    links : [
        {url: 'watches', title: 'Watches'},
        {url: 'jewelleries', title: 'Fine Jewellery'},
        {url: 'about', title: 'About Us'},
//        {url: 'events', title: 'Events'},
        {url: 'services', title: 'Our Services'},
        {url: 'boutique', title: 'Our Boutique'},
        {url: 'designers', title: 'Meet Our Designers'},
        {url: 'contact', title: 'Contact Us'}
    ],
    footerLogos : [
        {src: 'PatekPhilippe', alt: 'Patek Philippe', link:'/watches/patekPhilippe/'},
        {src: 'Cartier', alt: 'Cartier', link:'/watches/cartier/'},
        {src: 'IWC_Schaffhausen', alt: 'IWC Schaffhausen', link:'/watches/iwc/'},
        {src: 'Panerai', alt: 'Panerai', link:'/watches/panerai/'},
        {src: 'Wolf', alt: 'Wolf', link:'/watches/wolf/'},
/*        {src: 'PatekPhilippe', alt: 'Patek Philippe', link:'/watches/PatekPhilippe'},
        {src: 'Cartier', alt: 'Cartier', link:'/watches/Cartier'},
        {src: 'IWC_Schaffhausen', alt: 'IWC Schaffhausen', link:'/watches/IWC'},
        {src: 'Panerai', alt: 'Panerai', link:'/watches/Panerai'},
        {src: 'Wolf', alt: 'Wolf', link:'/watches/Wolf'},*/
    ],
    socialMedias:{
        facebook :'https://www.facebook.com/BrinkhausCalgary/',
        instagram : 'https://www.instagram.com/brinkhaus_jewellers/',
        twitter: 'https://www.twitter.com',
        pinterest: 'https://www.pinterest.com',
    },
};


export default CONSTANTS;
