import React, {useState, useEffect, lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import constants from '../../constants';

import Loading from '../pages/Loading';

const Header = lazy(() => import('../general/header'));
const Footer = lazy(() => import('../general/footer'));
const Home = lazy(() => import('../pages/Home'));
const About = lazy(() => import('../pages/About'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const Watches = lazy(() => import('../pages/Watches'));
const Events = lazy(() => import('../pages/Events'));
const Cartier = lazy(() => import('../pages/Cartier'));
const PatekPhilippe = lazy(() => import('../pages/PatekPhilippe'));
//const Wolf = lazy(() => import('../pages/Wolf'));
const Brand = lazy(() => import('../pages/Brand'));
const Collection = lazy(() => import('../pages/Collection'));
const Product = lazy(() => import('../pages/Product'));
const Services = lazy(() => import('../pages/Services'));
const Boutique = lazy(() => import('../pages/Boutique'));
const MeetDesginers = lazy(() => import('../pages/MeetDesginers'));
const Jewelleries = lazy(() => import('../pages/Jewelleries'));
const FAQ = lazy(() => import('../pages/FAQ'));
const Terms = lazy(() => import('../pages/Terms'));
const Policy = lazy(() => import('../pages/Policy'));
const DevPage = lazy(() => import('../pages/DevPage'));

const Layout = (props) => {
    let width = window.innerWidth;
    const initialSize = width < constants.MOBILE_SIZE ? constants.MOBILE : constants.DESKTOP;
    const [size, setSize] = useState(initialSize);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (width < constants.MOBILE_SIZE && size !== constants.MOBILE) {
                setSize(constants.MOBILE);
            } else if(width >= constants.MOBILE_SIZE && size === constants.MOBILE) {
                setSize(constants.DESKTOP);
            }
        })
    }, [size, width]);
    
    
    return (
        <Suspense fallback={<Loading/>}>
            <Container fluid className="w-100 px-0">
                <Header />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/home">
                        <Home />
                    </Route>
                    <Route exact path="/about">
                        <About/>
                    </Route>
                    <Route exact path="/contact">
                        <ContactUs openBookAppointment={false} />
                    </Route>
                    <Route exact path="/contact/:book">
                        <ContactUs openBookAppointment={true} />
                    </Route>
                    <Route exact path="/events">
                        <Events/>
                    </Route>
                    <Route exact path="/watches">
                        <Watches/>
                    </Route>
                        <Route exact path="/watches/PatekPhilippe">
                            <PatekPhilippe category="Watches" />
                        </Route>
                        <Route exact path="/watches/Cartier">
                            <Cartier category="Watches" />
                        </Route>
                        <Route exact path="/watches/:brand">
                            <Brand category="Watches" />
                        </Route>
                        <Route exact path="/watches/:brand/:collection">
                            <Collection category="Watches" />
                        </Route>
                        <Route exact path="/watches/:brand/:collection/:ref">
                            <Product category="Watches" />
                        </Route>
                    <Route exact path="/jewelleries">
                        <Jewelleries/>
                    </Route>
                        <Route exact path="/jewelleries/:brand">
                            <Brand category="jewelleries" />
                        </Route>
                        <Route exact path="/jewelleries/:brand/:collection">
                            <Collection category="jewelleries" />
                        </Route>
                        <Route exact path="/jewelleries/:brand/:collection/:ref">
                            <Product category="jewelleries" />
                        </Route>
                    <Route exact path="/services">
                        <Services />
                    </Route>
                    <Route exact path="/boutique">
                        <Boutique />
                    </Route>
                    <Route exact path="/designers">
                        <MeetDesginers />
                    </Route>
                    <Route exact path="/FAQ">
                        <FAQ />
                    </Route>
                    <Route exact path="/terms">
                        <Terms />
                    </Route>
                    <Route exact path="/policy">
                        <Policy />
                    </Route>
                    <Route exact path="/DevPage">
                        <DevPage/>
                    </Route>
                    <Route path="*">
                        <PageNotFound/>
                    </Route>
                </Switch>
                <Footer />
            </Container>
        </Suspense>
    )
}

export default Layout;
